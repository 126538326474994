var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('section',[_c('div',[_c('div',{staticClass:"mb-2"},[_c('h2',[_vm._v("Personal Information")])]),_c('div',[_c('b-card',[_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"newUserForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onUpdateUser($event)}}},[_c('div',{staticClass:"mb-2"},[_c('b-media',{staticClass:"text-center"},[_c('b-avatar',{ref:"previewEl",attrs:{"badge-variant":"transparent","src":_vm.avatarPath,"text":_vm.avatarText(((_vm.currentUser.first_name) + " " + (_vm.currentUser.last_name))),"variant":("light-" + (_vm.resolveStatusVariant(_vm.getLoanStatusTextForClient(_vm.getValueFromSource(_vm.currentUser, 'status'))))),"size":"10rem"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.avatar.$el.childNodes[0].click()}}},[_c('feather-icon',{attrs:{"icon":"CameraIcon"}})],1)]},proxy:true}],null,true)})],1),_c('div',[_c('b-form-file',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"avatar",attrs:{"accept":"image/*","placeholder":"","no-drop":""},on:{"input":_vm.onImageRenderer}})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.first_name),expression:"currentUser.first_name"}],attrs:{"id":"firstname","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "first_name", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.last_name),expression:"currentUser.last_name"}],attrs:{"id":"lastname","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "last_name", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.email),expression:"currentUser.email"}],attrs:{"id":"email","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "email", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.phone),expression:"currentUser.phone"}],attrs:{"id":"phone","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "phone", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Institution","label-for":"institution"}},[_c('validation-provider',{attrs:{"name":"institution","rules":"required","vid":"institution"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.institution),expression:"currentUser.institution"}],attrs:{"id":"institution","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.institution)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "institution", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Sector","label-for":"sector"}},[_c('validation-provider',{attrs:{"name":"sector","rules":"required","vid":"sector"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.sector),expression:"currentUser.sector"}],attrs:{"id":"sector","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.sector)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "sector", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Applicant Status","label-for":"applicant_status"}},[_c('validation-provider',{attrs:{"name":"applicant_status","rules":"required","vid":"applicant_status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.applicant_status),expression:"currentUser.applicant_status"}],attrs:{"id":"applicant_status","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.applicant_status)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "applicant_status", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Region","label-for":"region"}},[_c('validation-provider',{attrs:{"name":"region","rules":"required","vid":"region"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.region),expression:"currentUser.region"}],attrs:{"id":"region","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.region)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "region", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Area","label-for":"area"}},[_c('validation-provider',{attrs:{"name":"area","rules":"required","vid":"area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.area),expression:"currentUser.area"}],attrs:{"id":"area","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.area)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "area", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"District","label-for":"district"}},[_c('validation-provider',{attrs:{"name":"district","rules":"required","vid":"district"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.district),expression:"currentUser.district"}],attrs:{"id":"district","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.district)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "district", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Assembly","label-for":"assembly"}},[_c('validation-provider',{attrs:{"name":"assembly","rules":"required","vid":"assembly"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.assembly),expression:"currentUser.assembly"}],attrs:{"id":"assembly","autocomplete":"false","disabled":""},domProps:{"value":(_vm.currentUser.assembly)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "assembly", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('b-button',{staticClass:"py-1",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Update Profile ")])],1)])]}}])})],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }